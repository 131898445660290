@import "rsuite/dist/rsuite.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* .filterButton:hover {
    font-weight: 700;
    background-color: brown;
} */
.selected {
    background-color: rgb(180, 177, 177);
}

.compLogo {
    width: 130px;
    margin: 10px;
}
.crmlsLogo {
    width: 130px;
    margin-left: 800px;
}
body {
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
}

.summeryCardDiv {
    display: flex;
    margin-left: 7px;
}
/* .summeryCardDiv div {
    margin-left: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
} */

.summeryCard {
    margin-left: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 2px solid rgb(109, 108, 108);
    border-radius: 8px;
    overflow: hidden;
    width: 330px;
    height: 100px;
    background-color: rgb(249, 251, 253);
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-right: 2px;
    cursor: pointer;
}
.summerycardimg {
    margin-left: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.summeyDetails {
    margin-left: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cardImg {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}
.summerycardHeading {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 5px;
    /* background-color: rgb(179, 212, 245); */
    margin-top: 1px;
    margin-bottom: 0;
    overflow: hidden;
    color: #898282;
}
.summerycardResult {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 1px;
    color: black;
}

.exportcsvbtn {
    height: 40px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
}
.userLogin {
    width: 48px;
    height: 48px;
    cursor: pointer;
}
input.inputBox {
    font-size: 13px;
    width: 200px;
    padding: 8px 8px 8px 8px;
    border-radius: 3px;
    border: 1px solid grey;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
}

.calenderWrap {
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.calenderElement {
    position: absolute;
    z-index: 4;
}
.calenderImg {
    position: relative;
    margin-left: -30px;
    width: 17px;
    margin-bottom: -3px;
}
.ag-theme-alpine {
    width: 94%;
    height: 60vh;
    margin: auto;
    margin-top: 1px;
}
.imageSize {
    height: 390px;
}
.imagenot {
    height: 900px;
}
.ag-cell {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
}
/* .summeyDetails {
    margin-bottom: -4px;
} */

.openBtn {
    cursor: "pointer" !important;
    font-family: "Poppins" !important;
    background-color: rgb(239, 239, 239) !important;
    border-radius: 3px;
    overflow: hidden !important;
    margin-bottom: 2px;
    padding: 7px;
    font-size: 14px;
    line-height: 15px;
    border: 1px solid grey;
}
.loginacc {
    font-size: 26px;
    color: black;
}
.filterbtndiv {
    display: flex;
    align-items: center;
    width: 94%;
    margin: auto;
    justify-content: space-between;
}
.loginBox {
    width: 500px;
    height: 240px;
    border: "5px solid #a2b5e0";
    border-radius: 8px;
    background-color: #f2f6ff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emailInputfield {
    width: 320px;
    height: 30px;
    margin-bottom: 20px;
    margin-left: 61px;
}
.resetBox {
    width: 100%;
    height: 100vh;
    background-color: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.resetPassw {
    width: 500px;
    height: 240px;
    border: 5px solid #a2b5e0;
    border-radius: 8px;
    background-color: #f2f6ff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.resetInputfield {
    display: "flex";
    align-items: "baseline";
}
.restinput {
    width: 300px;
    height: 30px;
    margin-bottom: 20px;
}
@media (max-width: 550px) {
    .summeryCardDiv {
        flex-direction: column;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    .summeyDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        margin-left: 23px;
    }
    .summeryCard {
        width: 364px;
        margin-bottom: 0px;
        margin-top: 8px;
        margin-left: 12px;
    }
    .summerycardHeading {
        font-size: 16px;
    }
    .filterbtndiv {
        flex-direction: column;
        align-items: flex-start;
    }
    .comDate {
        margin-bottom: 10px;
    }
    .compLogo {
        width: 90px;
    }
    .crmlsLogo {
        width: 90px;
        margin-left: 105px;
    }
    .userLogin {
        width: 40px;
        height: 40px;
    }
    .usernameContainer {
        margin-left: 33%;
    }
    .summcard {
        margin-bottom: -10px;
    }
    .filterButton {
        margin-bottom: 10px;
    }
    .loginBox {
        width: 360px;
    }
    .emailInputfield {
        margin-left: 0px;
    }
    .resetPassw {
        width: 360px;
        height: 204px;
    }
    .resetInputfield {
        flex-direction: column;
    }
    .restinput {
        width: 324px;
        margin-bottom: 4px;
        margin-top: 6px;
    }
}
