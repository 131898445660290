.complianceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    background-color: #ffffff;
    padding: 1%;
    border-bottom: 1px solid #b3b1b1;
    box-shadow: 1px 1px 1px #b3b1b1;
}
.userStyle {
    border: 2px solid rgb(109, 108, 108);
    margin-left: 80px;
    width: 250px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    display: none;
    padding: 10px;
}
.userafterClick {
    border: 2px solid rgb(109, 108, 108);
    width: 250px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
}

.nw {
    display: none;
}
.nw1 {
    display: block;
}
.usernameContainer {
    position: absolute;
    margin-top: 5px;
    margin-left: 80%;
}
.resetPasswordbtn {
    margin-top: 5px;
    padding: 8px;
    width: 80%;
    cursor: pointer;
    font-family: poppins;
    border-radius: 6px;
    border: 1px solid gray;
    color: black;
}
.logoutbutton {
    margin-top: 10px;
    padding: 8px;
    width: 80%;
    cursor: pointer;
    font-family: poppins;
    border-radius: 6px;
    border: 1px solid grey;
    color: black;
}
.logCross {
    position: relative;
    width: auto;
    margin-top: -107px;
    margin-left: 224px;
}
@media (max-width: 550px) {
    .usernameContainer {
        margin-left: 33%;
        margin-top: 7px;
    }
    .resetPasswordbtn {
        width: 70%;
        padding: 4px;
    }
    .logoutbutton {
        width: 70%;
        padding: 4px;
    }
    .logCross {
        margin-top: -95px;
    }
}
